// src/components/guidesIndex.js
const guidesIndex = [
  {
    id: "guide-vinho",
    title: "Manchas de Vinho Tinto",
    stainType: "vinhotinto",
  },
  {
    id: "guide-caneta",
    title: "Manchas de Caneta",
    stainType: "caneta",
  },
  {
    id: "guide-sangue",
    title: "Manchas de Sangue",
    stainType: "sangue",
  },
  {
    id: "guide-frutas-vermelhas",
    title: "Manchas de Frutas Vermelhas",
    stainType: "frutasvermelhas",
  },
  {
    id: "guide-batom",
    title: "Manchas de Batom",
    stainType: "batom",
  },
  {
    id: "guide-molho-tomate",
    title: "Manchas de Molho de Tomate",
    stainType: "molhodetomate",
  },
  {
    id: "guide-tinta-vermelha",
    title: "Manchas de Tinta",
    stainType: "tinta",
  },
  {
    id: "guide-suor",
    title: "Manchas de Suor",
    stainType: "suor",
  },
  {
    id: "guide-oleo",
    title: "Manchas de Óleo",
    stainType: "óleo",
  },
  {
    id: "guide-manteiga",
    title: "Manchas de Manteiga ou Gordura",
    stainType: "manteigaougordura",
  },
  {
    id: "guide-frutas-amarelas",
    title: "Manchas de Frutas",
    stainType: "frutas",
  },
  {
    id: "guide-alimentos-acidos",
    title: "Manchas de Alimentos Ácidos",
    stainType: "alimentosácidos",
  },
  {
    id: "guide-cafe-leite",
    title: "Manchas de Café com Leite",
    stainType: "cafécomleite",
  },
  {
    id: "guide-frutas-roxas",
    title: "Manchas de Frutas Roxas",
    stainType: "frutasroxas",
  },
  {
    id: "guide-cha",
    title: "Manchas de Chá",
    stainType: "chá",
  },
  {
    id: "guide-suwor-desbotadas",
    title: "Manchas de Suor Desbotadas",
    stainType: "suordesbotadas",
  },
  {
    id: "guide-molho-branco",
    title: "Manchas de Molho Branco",
    stainType: "molhobranco",
  },
  {
    id: "guide-sabao",
    title: "Manchas de Sabão ou Detergente",
    stainType: "sabãooudetergente",
  },
  {
    id: "guide-alimentos-gordurosos",
    title: "Manchas de Alimentos Gordurosos",
    stainType: "alimentosgordurosos",
  },
  {
    id: "guide-graxa",
    title: "Manchas de Graxa",
    stainType: "graxa",
  },
  {
    id: "guide-graxa-pneus",
    title: "Manchas de Graxa de Pneus",
    stainType: "graxadepneus",
  },
  {
    id: "guide-cinza-cigarro",
    title: "Manchas de Cinza de Cigarro",
    stainType: "cinzadecigarro",
  },
  {
    id: "guide-cafe",
    title: "Manchas de Café",
    stainType: "café",
  },
  {
    id: "guide-chocolate",
    title: "Manchas de Chocolate",
    stainType: "chocolate",
  },
  {
    id: "guide-terra-lodo",
    title: "Manchas de Terra ou Lodo",
    stainType: "terraoulodo",
  },
  {
    id: "guide-sangue-seco",
    title: "Manchas de Sangue Seco",
    stainType: "sangueseco",
  },
  {
    id: "guide-molho-tomate-oxidado",
    title: "Manchas de Molho de Tomate Oxidado",
    stainType: "molhodetomateoxidado",
  },
  {
    id: "guide-acucar-caramelizado",
    title: "Manchas de Açúcar Caramelizado",
    stainType: "açúcarcaramelizado",
  },
  {
    id: "guide-mel",
    title: "Manchas de Mel",
    stainType: "mel",
  },

  // Adicione mais guias conforme necessário
];

export default guidesIndex;
